import React, { useState, useEffect } from "react";
import styles from "./radio_group.module.css";
import NotificationWidget from "ui/crfs/notification_widget";
import DividerLine from "ui/crfs/divider_line";
import RadioGroupUI from "./components/radio_group_ui";
import DataCompleteChangeModal from "ui/crfs/data_complete_change_modal";
import { cloneDeep } from "lodash";

const value_position = "value";
let tempStorage = null;

export default function RadioGroup(props) {
  const {
    field,
    title,
    options,
    instruction,
    instruction_color,
    sub_title,
    onUpdate,
    enableQuery,
    setQuery,
    query,
    crf_id,
    field_id,
    subject_id,
    domain_id,
    calcUpdateToCrfStatus,
    crfStatus,
    read_only,
  } = props;

  const [valueObj, setValueObj] = useState((field && field.value_obj) || {});
  const [showingCompleteChangeModal, setShowingCompleteChangeModal] =
    useState(false);

  useEffect(() => {
    setValueObj(field.value_obj || {});
  }, [crf_id, field]);
  const currentValueDisplay = options.filter(
    (o) => o.value === field.value_obj.value,
  );
  const updateValue = (value_obj) => {
    value_obj.currentValueDisplay =
      (options.filter((o) => o.value === value_obj.value).length &&
        options.filter((o) => o.value === value_obj.value)[0].label) ||
      "";

    if (crfStatus === "data_complete") {
      tempStorage = cloneDeep(value_obj);
      setShowingCompleteChangeModal(true);
      return;
    }
    setValueObj(value_obj);
    onUpdate(value_obj);
  };

  const acceptTempStorage = () => {
    setValueObj(tempStorage);
    onUpdate(tempStorage);
    tempStorage = null;
    setShowingCompleteChangeModal(false);
  };

  return (
    <div className={styles.outer_wrapper}>
      <div className={styles.input_flex}>
        <div className={styles["left-split"]}>
          {enableQuery ? (
            <div>
              <NotificationWidget
                {...props}
                title={title}
                currentValueDisplay={
                  (currentValueDisplay &&
                    currentValueDisplay.length &&
                    currentValueDisplay[0].label) ||
                  ""
                }
                instruction={instruction}
                query={query}
                setQuery={setQuery}
                crf_id={crf_id}
                field_id={field_id}
                subject_id={subject_id}
                domain_id={domain_id}
                calcUpdateToCrfStatus={calcUpdateToCrfStatus}
              />
            </div>
          ) : (
            <div>{title}</div>
          )}
          {sub_title && (
            <div className={styles.sub_description}>{sub_title}</div>
          )}
          {instruction ? (
            <div>
              <div
                style={{
                  color: instruction_color ? instruction_color : "#000",
                }}
              >
                {instruction}
              </div>
            </div>
          ) : null}
        </div>
        {read_only || field.read_only ? (
          <div>
            {(currentValueDisplay &&
              currentValueDisplay.length &&
              currentValueDisplay[0].label) ||
              "---"}
          </div>
        ) : (
          <RadioGroupUI
            {...props}
            field={{ ...field, value_obj: valueObj }}
            key_prefix={crf_id}
            value_position={value_position}
            onUpdate={(value_obj) => updateValue(value_obj)}
          />
        )}
      </div>
      <DividerLine />
      <DataCompleteChangeModal
        isOpen={showingCompleteChangeModal}
        close={() => setShowingCompleteChangeModal(false)}
        onYes={acceptTempStorage}
      />
    </div>
  );
}
